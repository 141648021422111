<template>
    <v-container fluid>
        <base-material-card icon="fas fa-file-invoice" title="Reporte general de facturas" class="px-5 py-3">
            <v-row>
                <v-col cols="4">
                    <v-menu v-model="menu_fecha_inicio" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="pagination.fecha_inicial" label="Fecha inicial" readonly v-on="on" :error-messages="errors.fecha_inicial"></v-text-field>
                        </template>
                        <v-date-picker v-model="pagination.fecha_inicial" @input="menu_fecha_inicio = false; load()" locale="es-MX"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="4">
                    <v-menu v-model="menu_fecha_final" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="pagination.fecha_final" label="Fecha final" readonly v-on="on" :error-messages="errors.fecha_final"></v-text-field>
                        </template>
                        <v-date-picker v-model="pagination.fecha_final" @input="menu_fecha_final = false; load()" locale="es-MX"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="4">
                    <v-select v-model="pagination.emisor_id" @input="load" :items="emisores" label="Emisor" item-value="id" item-text="razon_social" :error-messages="errors.categoria_id" clearable></v-select>
                </v-col>
                <v-col cols="4">
                    <v-select v-model="pagination.cliente_id" @input="load" :items="clientes" label="Cliente" item-value="id" item-text="razon_social" :error-messages="errors.categoria_id" clearable></v-select>
                </v-col>
              <v-col cols="4">
                <v-select v-model="pagination.estatus" @input="load"
                          label="Estatus"
                          :items="cancelacionEstatus"
                          item-value="id"
                          item-text="nombre"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select v-model="pagination.vendedor_id" @input="load" :items="vendedores" label="Vendedor" item-value="id"
                          item-text="razon_social" clearable></v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Folio Fiscal"
                              v-model="pagination.folio_fiscal"
                              @input="() => load()"
                              class=""></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Folio Venta"
                              v-model="pagination.venta_id"
                              @input="() => load()"
                              class=""></v-text-field>
              </v-col>
                <v-col cols="12">
                    <v-btn color="primary" @click="downloadExcel">Descargar</v-btn>
                    <v-btn
                      color="success"
                      :disabled="facturas.length === 0"
                      :loading="loadingAllXml"
                      @click="downloadAllXml()"
                    >Descargar todos los XML</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-data-table :headers="headers" :items="facturas" :loading="loading" hide-default-footer disable-pagination>
                        <template v-slot:item.venta_id="{item}">
                            {{item.venta_id.toString().padStart(5, "0")}}
                        </template>
                        <template v-slot:item.total="{item}">
                            {{formatoMoneda(item.total)}}
                        </template>
                        <template v-slot:item.acciones="{item}">
                          <template v-if="!item.cancelacion">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn fab icon x-small v-on="on" @click="downloadXml(item)">
                                  <v-icon>far fa-file-code</v-icon>
                                </v-btn>
                              </template>
                              <span>Descargar XML</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn v-if="item.pdf_url !== null" fab icon x-small v-on="on" @click="downloadPdf(item)">
                                  <v-icon>far fa-file-pdf</v-icon>
                                </v-btn>
                              </template>
                              <span>Descargar PDF</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn v-if="item.pdf_url === null" fab icon x-small v-on="on" @click="retryDownloadPdf(item)">
                                  <v-icon>far fa-hdd</v-icon>
                                </v-btn>
                              </template>
                              <span>Reintentar generación de PDF</span>
                            </v-tooltip>
                            <v-tooltip top v-if="item.venta.cancelada">
                              <template v-slot:activator="{ on }">
                                <v-btn fab icon x-small v-on="on" @click="showCancelacionErrors(item)">
                                  <v-icon color="red">fas fa-exclamation</v-icon>
                                </v-btn>
                              </template>
                              <span>Error al Cancelar</span>
                            </v-tooltip>
                          </template>
                          <template v-else>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn color="red" fab icon x-small v-on="on" @click="downloadXmlCancelacion(item)">
                                  <v-icon color="red">far fa-file-code</v-icon>
                                </v-btn>
                              </template>
                              <span>Descargar XML Cancelacion</span>
                            </v-tooltip>
                          </template>

                        </template>
                        <template v-slot:body.append>
                            <tr>
                                <td colspan="6"></td>
                                <td class="font-weight-bold">{{formatoMoneda(total)}}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col col="12">
                    <v-pagination v-model="pagination.page" class="my-4" :length="pagination.last_page" @input="load" :total-visible="10" color="primary"></v-pagination>
                </v-col>
            </v-row>
        </base-material-card>
      <v-dialog v-model="haveCancelacionesErrors">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Errores al cancelar factura</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="haveCancelacionesErrors = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-simple-table v-if="factura">
                  <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Mensaje</th>
                  </tr>
                  </thead>
                  <tr v-for="error in factura.cancelacion_errors">
                    <td>{{error.fecha_cancelacion}}</td>
                    <td>{{error.mensaje}}</td>
                  </tr>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
</template>
<script>
    import moment from 'moment'
    export default {
        name: 'ReportesFacturasGeneral',
        data: () => ({
            errors: {},
            clientes: [],
            vendedores: [],
            emisores: [],
            facturas: [],
            factura:null,
            haveCancelacionesErrors:false,
            headers: [
                { text: 'Venta', value: 'venta_id', sortable: false },
                { text: 'Fecha', value: 'fecha_factura', sortable: false },
                { text: 'Emisor', value: 'emisor.rfc', sortable: false },
                { text: 'Folio', value: 'folio_fiscal', sortable: false },
                { text: 'Cliente', value: 'cliente.razon_social', sortable: false },
                { text: 'Vendedor', value: 'venta.vendedor.razon_social', sortable: false },
                { text: 'Importe', value: 'total', sortable: false },
                { text: 'Acciones', value: 'acciones', sortable: false },
            ],
            pagination: {
                fecha_inicial: null,
                fecha_final: null,
                estatus:'',
                cliente_id:'',
                emisor_id:'',
                folio_fiscal:'',
                venta_id:'',
                vendedor_id:'',
                page:1,
            },
            menu_fecha_inicio: false,
            menu_fecha_final: false,
            loading: false,
            loadingAllXml: false,
            total: 0,
          cancelacionEstatus:[
            {
              id: '',
              nombre: 'Todos',
            },
            {
              id: 'true',
              nombre: 'Activas',
            },
            {
              id: 'false',
              nombre: 'Canceladas',
            },
          ],
        }),
        mounted() {
            this.setInitialDates()
            this.load()
            this.loadClientes()
            this.loadEmisores()
            this.loadVendedores()
        },
        methods: {
          loadVendedores() {
            this.$http.get('/vendedores/all').then(response => {
              this.vendedores = response.data
            })
          },
          showFormatDate(fecha){
            return moment(fecha).format('YYYY-MM-DD HH:mm:ss')
          },
          showCancelacionErrors(factura){
            this.factura = factura;
            this.haveCancelacionesErrors = true;
          },
            load() {
                this.loading = true
                this.facturas = []
                this.$http.get('/reportes/facturas/general', {
                    params: this.pagination
                }).then(response => {
                    this.facturas = response.data.data
                    this.pagination.last_page = response.data.meta.last_page
                    this.pagination.page = response.data.meta.current_page
                    this.total =  response.data.total
                }).finally(() => {
                    this.loading = false
                })
            },
            loadClientes() {
                this.$http.get('/clientes/all').then(response => {
                    this.clientes = response.data
                })
            },
            loadEmisores() {
                this.$http.get('/emisores/all').then(response => {
                    this.emisores = response.data
                })
            },
            downloadPdf(item) {
              this.loadingPdf = true;
              this.$http({
                url: `/facturas/${item.id}/pdf`,
                method: "GET",
                responseType: "blob",
                params: this.form,
              }).then((response) => {
                const downloadUrl = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.setAttribute("download", `factura.pdf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
              }).finally(() => {
                this.loadingPdf = false;
              });
            },

            downloadXml(item) {
              this.loadingPdf = true;
              this.$http({
                url: `/facturas/${item.id}/xml`,
                method: "GET",
                responseType: "blob",
                params: this.form,
              }).then((response) => {
                const downloadUrl = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.setAttribute("download", `factura.xml`);
                document.body.appendChild(link);
                link.click();
                link.remove();
              }).finally(() => {
                this.loadingPdf = false;
              });
            },
            downloadExcel(item) {
                window.location.assign(`/reportes/facturas/general/excel?${jQuery.param(this.pagination)}`)
            },
          downloadXmlCancelacion(item) {
            window.location.assign(`/facturas/${item.id}/xml/cancelacion`)
          },
            setInitialDates() {
                this.pagination.fecha_inicial = moment().startOf('day').format('YYYY-MM-DD')
                this.pagination.fecha_final = moment().startOf('day').format('YYYY-MM-DD')
            },
            retryDownloadPdf(item) {
                this.$http.patch(`/facturas/${item.id}/retry-download`).then(response => {
                  this.$store.commit('SET_SNACKBAR', {
                    text: "Solicitud de generación de PDF exitosa; intente descarga más tarde",
                    color: 'success'
                  })
                })
            },

            downloadAllXml() {
                this.loadingAllXml = true;

                this.$http({
                    url: '/facturas/zip/all-xml',
                    method: 'GET',
                    responseType: 'blob',
                    params: this.pagination,
                }).then((response) => {
                    const downloadUrl = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', 'facturas.zip');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(function(error) {
                  console.log(error);
                  this.loadingAllXml = false;
                }).finally(() => {
                  setTimeout(() => {
                    this.loadingAllXml = false;
                  }, 1000);
                });
            },
        }
    }
</script>
